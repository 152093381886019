exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-feedlp-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/feedlp.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-feedlp-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-hikari-tv-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/hikari-tv.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-hikari-tv-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-lp-wordpress-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/lp-wordpress.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-lp-wordpress-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-momoko-planet-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/momoko-planet.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-momoko-planet-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-original-cms-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/original-cms.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-original-cms-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-packetlabs-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/packetlabs.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-packetlabs-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-primasun-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/primasun.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-primasun-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-s-portal-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/s-portal.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-s-portal-md" */),
  "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-social-game-md": () => import("./../../../src/pages/portfolio/{Mdx.frontmatter__slug}.tsx?__contentFilePath=/home/portfolio2023/contents/portfolios/social-game.md" /* webpackChunkName: "component---src-pages-portfolio-mdx-frontmatter-slug-tsx-content-file-path-contents-portfolios-social-game-md" */)
}

